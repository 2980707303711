
import { Navigation } from "@/mixins/navigation";
import { Permissions } from "@/mixins/permissions";
import PageTitle from "@/components/General/PageTitle.vue";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import Loader from "@/components/General/Loader.vue";
import Empty from "@/components/General/Empty.vue";
import { Notification } from "@/models/notification.interface";
import { Product as IProduct } from "@/models/product.interface";
import BarSearch from "@/components/Input/BarSearch.vue";
import DotsMenu from "@/components/General/DotsMenu.vue";
@Component({
  components: { PageTitle, Loader, Empty, BarSearch, DotsMenu },
})
export default class Products extends mixins(Navigation, Permissions) {
  loader = false;
  loading = false;
  loadingDT = false;
  dialogDelete = false;
  status = [this.$constants.STATUS.ACTIVE, this.$constants.STATUS.INACTIVE];
  defaultProduct = {
    id: "",
    name: "",
    status: {
      name: "",
    },
  };
  product = { ...this.defaultProduct };
  search = "";
  options = {};

  pagination = {
    itemsPerPage: parseInt(process.env.VUE_APP_DEFAULT_PAGINATION),
    totalItems: 0,
    page: 1,
  };

  private async searchProductByText() {
    this.loadingDT = true;

    await this.getProducts(
      this.pagination.page,
      this.pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage,
      this.search
    );
    this.loadingDT = false;
  }

  @Watch("options", { deep: true })
  private async setItems(pagination) {
    this.loadingDT = true;
    this.pagination.itemsPerPage = pagination.itemsPerPage;

    await this.getProducts(
      pagination.page,
      pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage,
      this.search
    );
    this.loadingDT = false;
  }

  private async getProducts(
    page: number,
    size: number,
    text = ""
  ): Promise<void> {
    await this.$store
      .dispatch("products/getProducts", { page, size, text })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Products.fetchError.products"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  private async created() {
    this.loader = true;
    await this.getProducts(
      this.pagination.page,
      this.pagination.itemsPerPage,
      this.search
    );
    this.loader = false;
  }

  private get headers() {
    return [
      {
        text: this.$tc("Products.headers.image"),
        value: "image",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Products.headers.name"),
        value: "name",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Products.headers.unit_price"),
        value: "price",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Products.headers.total_price"),
        value: "total_price",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Products.headers.quantity"),
        value: "quantity",
        align: "center",
        sortable: false,
      },

      {
        text: this.$tc("Products.headers.status"),
        value: "status",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Products.headers.actions"),
        value: "actions",
        sortable: false,
        align: "center",
      },
    ];
  }

  private get products(): IProduct[] {
    let response = this.$store.getters["products/getProducts"];
    this.pagination.totalItems = response.totalItems;
    this.pagination.page = parseInt(response.currentPage);

    if (response?.products) {
      return response.products;
    } else {
      return [];
    }
  }

  private async createProduct() {
    this.$router.push(
      `/staff/products/create/${this.$constants.OFFER.INVESTMENT}`
    );
  }

  private async editProduct(id: string) {
    this.$router.push(`/staff/products/update/${id}`);
  }

  private async deleteProductConfirm(product) {
    this.product = { ...product };
    this.dialogDelete = true;
  }

  private async productDetail(id: string) {
    this.$router.push(`/staff/products/detail/${id}`);
  }

  private async deleteProduct() {
    this.loading = true;
    await this.$store
      .dispatch("products/updateProductStatus", {
        id: this.product.id,
        status: this.$constants.STATUS.DELETED,
      })
      .then(async () => {
        const Success: Notification = {
          message: this.$tc("Products.deleteSuccess"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: false,
        };

        this.$store.dispatch("notifications/showNotification", Success);
        await this.getProducts(
          this.pagination.page,
          this.pagination.itemsPerPage == -1
            ? this.pagination.totalItems
            : this.pagination.itemsPerPage,
          this.search
        );
        this.loading = false;
        this.closeDelete();
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Products.deleteError"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);

        this.loading = false;
      });
  }

  private closeDelete() {
    this.dialogDelete = false;
    this.product = Object.assign(this.product, this.defaultProduct);
  }
}
